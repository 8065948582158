import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Меню | Насолоджуйтесь смаком у Quart
			</title>
			<meta name={"description"} content={"Побалуйте себе симфонією смаків"} />
			<meta property={"og:title"} content={"Меню | Насолоджуйтесь смаком у Quart"} />
			<meta property={"og:description"} content={"Побалуйте себе симфонією смаків"} />
			<meta property={"og:image"} content={"https://quartzonfree.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quartzonfree.com/img/6978255.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quartzonfree.com/img/6978255.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quartzonfree.com/img/6978255.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quartzonfree.com/img/6978255.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quartzonfree.com/img/6978255.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quartzonfree.com/img/6978255.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="64px 0" sm-padding="40px 0" font="--base" color="--dark">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h1"
				font="--headline1"
				md-font="--headline2"
				max-width="520px"
				margin="0 auto 5px auto"
				text-align="center"
			>
				Основні позиції меню
			</Text>
			<Text
				as="p"
				color="--grey"
				max-width="520px"
				margin="0 auto"
				text-align="center"
				font="--lead"
			>
				Пориньте у серце Quart з нашим ретельно розробленим меню, створеним для того, щоб спокусити ваші смакові рецептори і викликати бажання скуштувати ще. Наші пропозиції - це свято як традиційних, так і сучасних смаків, що гарантує, що кожен знайде щось для себе. Цей погляд на наш кулінарний світ - лише початок того, що ми можемо запропонувати.
			</Text>
			<Box
				margin="40px -16px -16px -16px"
				display="grid"
				flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="24px"
				md-grid-gap="16px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="32px"
				width="100%"
			>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://quartzonfree.com/img/6.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Класичні піци
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Відчуйте сутність Італії з нашою Маргаритою, приправленою свіжим базиліком, томатним соусом та сиром моцарела, або насолодіться насиченим смаком нашого Пепероні, вічного фаворита.
						<br />
						<br />
						
Маргарита: Королева піци, прикрашена томатним соусом Сан-Марцано, свіжою моцарелою, листям базиліка та краплиною оливкової олії першого віджиму. Вічна класика, яка віддає данину поваги кольорам італійського прапора.
						<br />
Пепероні: Міцна та гостра улюблена піца, з щедрим шаром скибочок пепероні на шарі сиру моцарела та нашим фірмовим томатним соусом, запечена до хрусткої скоринки.
						<br />
Чотири сири: Мрія любителів сиру, що поєднує в собі кремову текстуру та витончені смаки моцарели, горгонзоли, пармезану та рікотти, розплавлених разом на нашому класичному тісті.
						<br />
Гавайська: Суперечлива класика, яка знаходить гармонію в солоно-солодкому поєднанні шинки та ананаса, з сиром моцарела та пікантною томатною основою.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://quartzonfree.com/img/7.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Фірмові творіння
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Пориньте у смакову пригоду з нашими фірмовими піцами, такими як "Грибна з трюфелями" з вершковим трюфельним соусом та лісовими грибами або "Гостра Діаволо" для тих, хто наважиться додати жару.
						<br />
						<br />
						
Трюфельний гриб: Вишукана насолода для гурманів, яка демонструє багату суміш лісових грибів у вершковому білому соусі з трюфелями, доповнена сиром моцарела та посипана свіжою петрушкою для додання земляного, ароматного смаку.
						<br />
Гострий Діаволо: Для тих, хто жадає спеки, ця піца розпалює вогонь гострою італійською ковбасою, халапеньйо, пластівцями червоного перцю чилі та гострим томатним соусом, збалансованим з прохолодою сиру моцарела.
						<br />
Курка барбекю: Сучасний варіант піци з ніжними шматочками курячої грудки, маринованими в димчастому соусі барбекю, червоною цибулею, кінзою та сумішшю сирів моцарела і чеддер.
						<br />
Песто "Сад": Зелений і яскравий варіант, з основою з домашнього соусу песто, увінчаний асорті з сезонних овочів, козячого сиру і посипаний кедровими горішками для свіжого і ароматного смаку.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Свіжі салати: 
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Доповніть піцу нашим вибором свіжих салатів, від хрусткого салату "Цезар" до яскравого середземноморського салату, приготованих з найсвіжіших місцевих інгредієнтів.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Напої
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Освіжіть свій смак нашими напоями ручної роботи, включаючи ремісничу газовану воду, місцеве крафтове пиво та ретельно підібрану винну карту.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Спеціальні послуги
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
					Приватні заходи: Quart пропонує унікальне місце для ваших особливих подій. Від святкування дня народження до корпоративних зустрічей, дозвольте нам зробити вашу подію незабутньою.
Кейтерингові послуги: Принесіть смак Quart до вашого порогу з нашими кейтеринговими послугами, які ідеально підходять для зустрічі будь-якого розміру.

					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 500 32px/1.2 --fontFamily-serifTimes"
						padding="0px 50px 0px 0px"
						lg-margin="0px 0px 50px 0px"
						md-padding="0px 0 0px 0px"
						md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
						sm-margin="0px 0px 35px 0px"
					>
						Зв'яжіться з нами
					</Text>
					<Box min-width="10px" min-height="10px" display="flex" sm-flex-direction="column">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							margin="0px 25px 0px 0px"
							sm-margin="0px 0 10px 0px"
						>
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								Телефон
							</Text>
							<Link href="tel:096 096 61 66" color="#000000" text-decoration-line="initial" font="--lead">
								096 096 61 66
							</Link>
						</Box>
						<Box min-width="10px" min-height="10px" display="flex" margin="0px 0 0px 0px">
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								E-mail
							</Text>
							<Link href="mailto:manger@quartzonfree.com" color="#000000" text-decoration-line="initial" font="--lead">
								manger@quartzonfree.com
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});